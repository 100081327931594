<template>
  <legend class="pt-2 pb-2">
    <div class="row">
      <div class="col-lg-3">
        <span class="font-weight-bolder text-dark">{{ title }}</span>
        <p class="text-muted mt-1 font-weight-bold font-size-sm">
          {{ description }}
        </p>
        <hr />
      </div>
      <div class="col-lg-9">
        <slot></slot>
      </div>
    </div>
  </legend>
</template>

<style lang="scss">
legend {
  hr {
    width: 30px;
    border: 1px solid #1a1a27;
  }
}
</style>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "Title"
    },
    description: {
      type: String,
      default: "Description of a page"
    }
  }
};
</script>
