<style lang="scss">
.form-loader-transparent {
  position: absolute;
  height: 100%;
  background: #00000059;
  z-index: 1;
  width: 100%;
  text-align: center;
  margin: auto;
}
</style>
<template>
  <div class="form-loader-transparent">
    <data-loader
      :type="'dark'"
      :padding="10"
      :style="'margin-top:50px'"
    ></data-loader>
  </div>
</template>

<script>
import dataLoader from "@/includes/content/DataLoader";
export default {
  components: {
    "data-loader": dataLoader
  }
};
</script>
